import { getDateFromToday, getHora } from '@portal-internet/core';
import clsx from 'clsx';
import { typesViewport, useViewport } from 'hooks/useViewport';
import CodiEspectador from 'components/3cat/CodiEspectador';
import Enllac from 'components/3cat/Enllac';
import Imatge from 'components/3cat/Imatge';
import Icona from 'components/3cat/ui/Icona';
import LogoDirecte from 'components/3cat/ui/LogoDirecte';
import ProgressBar from 'components/3cat/ui/ProgressBar';
import { useProducte } from 'hooks/3cat/useProducte';
import __findIndex from 'lodash/findIndex';
import __isEmpty from 'lodash/isEmpty';
import { useEffect, useRef, useState } from 'react';
import { FreeMode, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import { canalDiccionari } from 'utils/canals';
import { getNomCanal } from 'utils/nomCanalHelper';
import { addSlideClass, calculaUrlBotoMes, getInfoTitol, getPropsSwiper } from './funcions';
import styles from './slider.module.scss';

const _getCanalRoute = (canal) => {
    switch (canal) {
        case 'C33':
            return '33';
        case 'CAD_ES3':
            return 'esport3';
        case 'CAD_OCA1':
        case 'CAD_OCA2':
        case 'CAD_OCA3':
        case 'CAD_OCA4':
            return 'oca1';
        case 'PUCFC1':
            return 'fc1';
        case 'PUCFC2':
            return 'fc2';
        case 'PUCOCA1':
            return 'pucoca1';
        case 'PUCOCA2':
            return 'pucoca2';
        case 'PUCOCA3':
            return 'pucoca3';
        case 'BEAUTIES':
            return 'beauties';
        default:
            return canal;
    }
};
const _getCanal = (canal) => {
    switch (canal) {
        case 'CAD_ES3':
            return 'esport3';
        case 'CAD_OCA1':
        case 'CAD_OCA2':
        case 'CAD_OCA3':
        case 'CAD_OCA4':
            return 'oca1';
        default:
            return canal;
    }
};

const CustomContainer = (props) => {
    const { tipus, canal, tipusDirecte } = props;
    const [isHover, setIsHover] = useState(false);
    const [divHover, setDivHover] = useState('');
    const canalRoute = ['tvc', 'tvi'].includes(canal) ? 'tv3' : _getCanalRoute(canal);

    useEffect(() => {
        setDivHover('');
        if (isHover) {
            setTimeout(() => {
                if (isHover) setDivHover(clsx({ [styles.itemHover]: true }));
            }, 200);
        }
    }, [isHover]);

    const hoverHandler = (e) => {
        setIsHover(true);
    };
    const outHandler = () => {
        setIsHover(false);
    };

    if (tipus === 'DIRECTES' || tipus === 'FAST_CHANNEL') {
        const route = {
            tipologia: 'DIRECTES_ARAFEM',
            id: canalRoute,
            tipusDirecte
        };
        return (
            <div onMouseEnter={hoverHandler} onMouseLeave={outHandler}>
                <Enllac className={`${styles.wrapper} ${divHover}`} route={route}>
                    {props.children}
                </Enllac>
            </div>
        );
    } else {
        return (
            <div className={`${styles.wrapper} ${divHover}`} onMouseEnter={hoverHandler} onMouseLeave={outHandler}>
                {props.children}
            </div>
        );
    }
};
const canal = 'c33';

const _getEtiquetaDirecteFutur = (data_emissio) => {
    const dataSplited = data_emissio.split('/');
    const d = new Date(Date.parse(dataSplited[1] + '/' + dataSplited[0] + '/' + dataSplited[2]));
    const dies = ['DIUMENGE', 'DILLUNS', 'DIMARTS', 'DIMECRES', 'DIJOUS', 'DIVENDRES', 'DISSABTE'];
    // DIUMENGE 10 | 10.30
    const dia = dies[d.getDay()] + ' ' + (d.getDate() < 10 ? '0' : '') + d.getDate();
    const hora = (d.getHours() < 10 ? '0' : '') + d.getHours() + '.' + (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();

    let diesFromToday = getDateFromToday(d);
    let textDia = '';
    if (diesFromToday) {
        textDia = diesFromToday;
    } else {
        textDia = dia;
    }
    return textDia + ' | ' + hora;
};

const _updateImatges = (e) => {
    const imatges = e?.ara_fem?.imatges;
    const destacat_imatge = e?.ara_fem?.destacat_imatge;

    if (!__isEmpty(imatges) && destacat_imatge) {
        return [...imatges, { text: destacat_imatge, mida: 'DESTACAT_IMATGE', rel_name: 'IMATGE_CUSTOM' }];
    } else if (destacat_imatge) {
        return [{ text: destacat_imatge, mida: 'DESTACAT_IMATGE', rel_name: 'IMATGE_CUSTOM' }];
    } else {
        return imatges;
    }
};

const _getCapitol = (e) => {
    let text = '';
    if (e.ara_fem?.tipus === 'radio') {
        if (e.name === 'cm' && e.ara_fem?.ara_sona?.ccmaarasona === 'SI')
            text =
                e.ara_fem?.ara_sona?.autor +
                (e.ara_fem?.ara_sona?.autor && e.ara_fem?.ara_sona?.fragment_obra ? ' - ' : '') +
                e.ara_fem?.ara_sona?.fragment_obra;
        else if (e.name === 'ic' && e.ara_fem?.ara_sona?.ccmaarasona === 'SI')
            text =
                e.ara_fem?.ara_sona?.titol +
                (e.ara_fem?.ara_sona?.titol && e.ara_fem?.ara_sona?.artista ? ' - ' : '') +
                e.ara_fem?.ara_sona?.artista;
        if (__isEmpty(text) && !__isEmpty(e.ara_fem.presentador)) {
            text = 'Amb ' + e.ara_fem.presentador;
        }
    } else {
        text = e.ara_fem?.titol_capitol || e.ara_fem?.capitols?.[0].desc;
    }
    return text;
};

const _getInfoVideo = (tipus, e, isFastChannel) => {
    if (isFastChannel) {
        const canal = e.canals
            ?.map((canal) => {
                canal.main === true;
                return canal.id;
            })
            ?.toString();
        return {
            titol: e.avantitol,
            capitol: e.entradeta,
            link: canal,
            canal: canal,
            logo: canal,
            imatge: e.imatges,
            tipusDirecte: 'tv',
            drets_cat: true
        };
    } else {
        return tipus === 'DIRECTES'
            ? {
                  titol: e.ara_fem?.titol_programa,
                  capitol: _getCapitol(e),
                  link: e.ara_fem?.codi_canal,
                  canal: e.ara_fem?.codi_canal,
                  logo: e.ara_fem?.logo,
                  imatge: _updateImatges(e),
                  start_time: e.ara_fem?.start_time,
                  end_time: e.ara_fem?.end_time,
                  codi_etic: e.ara_fem?.codis_etics,
                  drets_cat: e.ara_fem?.drets_cat == 'no' ? false : true,
                  tipusDirecte: e.ara_fem?.tipus
              }
            : {
                  titol: e.titol,
                  capitol: e.capitols[0].desc,
                  canal: e.canals[0].id,
                  logo: e.canals[0].desc,
                  imatge: e.imatges,
                  codi_etic: e.codi_etic,
                  data_emissio: e.data_emissio
              };
    }
};
const _getTotsCanals = (items, canalExclos) => {
    //Mirem si tenim info o no 33 per afegir el canal inactiu
    const posCanal = __findIndex(items, { name: canal });
    const part1 = items.slice(0, posCanal);
    const part2 =
        !items || !items[posCanal]?.ara_fem
            ? { name: canal, ara_fem: canalDiccionari.c33 }
            : { name: canal, ara_fem: items[posCanal].ara_fem };
    const part3 = items.slice(posCanal, posCanal.length);

    //Afegim Bon Dia TV
    const part4 = { name: 'bdtv', ara_fem: canalDiccionari.bdtv };

    return canalExclos !== 'c33' ? (items = [...part1, part2, ...part3, part4]) : (items = [...part1, ...part3, part4]);
};

const _getInfoDespres = (tipus, e) => {
    return tipus === 'DIRECTES'
        ? e
            ? {
                  df_hora: getHora(e.start_time),
                  df_titol: e.titol_programa,
                  df_entradeta: e.titol_capitol
              }
            : {}
        : {};
};

const _netejaTitolTira = (titol) => {
    if (!titol) return titol;
    return titol.replace(/\*\[([^\][]*)]\*/g, '').trim();
};

const _orderItems = (items, order, tipus) => {
    if (tipus === 'FAST_CHANNEL') {
        return items;
    }
    const items1 = items.filter((item) => item.ara_fem?.tipus === order);
    const items2 = items.filter((item) => item.ara_fem?.tipus !== order);
    const itemsOrdenats = [...items1, ...items2];
    const posCanalCr = __findIndex(itemsOrdenats, { name: 'cr' });
    const posCanalOcr1 = __findIndex(itemsOrdenats, { name: 'ocr1' });
    if (posCanalCr >= 0 && posCanalOcr1 >= 0) {
        const partOcr1 = itemsOrdenats[posCanalOcr1];
        const items2 = itemsOrdenats.filter((item) => item.name !== 'ocr1');
        const part1 = items2.slice(0, posCanalCr + 1);
        const part3 = items2.slice(posCanalCr + 1, posCanalCr.length);
        return [...part1, partOcr1, ...part3];
    }
    return items;
};

const _getDestacatsFastChannel = (destacats, canal) => {
    return destacats.filter((item) => !item.canals?.[0]?.id?.toLowerCase().includes(canal));
};
export default function Slider(props) {
    let { items, isFastChannel, excloureCanalRouterQuery, routerQuery } = props;
    const { canalExclos, totsCanals, tipus, has_link, order } = props;
    const titol = _netejaTitolTira(props.titol);
    const navigationPrev = useRef(null);
    const navigationNext = useRef(null);
    const swiper = useRef(null);
    const [classPos, setClassPos] = useState('');
    const [swiperInit, setSwiperInit] = useState(false);
    const [itemsOrdenats, setItemsOrdenats] = useState(null);
    let resizeTimeOut;

    const { isWebviewActive } = useProducte();
    const { viewport } = useViewport();

    const iconaTitol = tipus === 'DIRECTES' || tipus === 'FAST_CHANNEL' ? 'directes' : '';
    const iconaTitolAlt = tipus === 'DIRECTES' || tipus === 'FAST_CHANNEL' ? 'Icona directes' : '';

    const stylesSwiper = clsx({
        [styles.innerSlider]: true,
        [styles.dreta]: classPos === 'openRight',
        [styles.esquerra]: classPos === 'openLeft'
    });

    useEffect(() => {
        items =
            totsCanals && tipus === 'DIRECTES'
                ? _getTotsCanals(items, canalExclos)
                : excloureCanalRouterQuery && routerQuery?.canal
                ? _getDestacatsFastChannel(items, routerQuery?.canal)
                : items;
        setItemsOrdenats([..._orderItems([...items], order, tipus)]);
        swiper.current.swiper.slideTo(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [routerQuery?.canal]);

    const stylesModul = clsx({
        [styles.init]: swiperInit,
        [styles.slider]: true,
        [styles.cDirecte]: true,
        [styles.webview]: isWebviewActive || viewport === typesViewport.VP_MOBILE
    });

    const _buildSlide = (element, i) => {
        const finalInfo = _getInfoVideo(tipus, element, isFastChannel);
        const despresInfo = _getInfoDespres(tipus, element.despres_fem);
        const { canal, logo, imatge, start_time, end_time, drets_cat, data_emissio, tipusDirecte } = finalInfo;
        const icona = tipusDirecte !== 'tv' && !canal?.startsWith('oca') ? 'auriculars_ple' : '';
        let { codi_etic } = props;

        const itemTitol = finalInfo.titol,
            itemCapitol = finalInfo.capitol;
        if ((__isEmpty(itemTitol) && __isEmpty(imatge) && __isEmpty(finalInfo.link)) || canal == canalExclos) {
            return false;
        }
        const styleIconaAuriculars = clsx({
            [styles.overIcon]: true,
            [styles.overIconKeyframe]: true
        });

        return (
            <SwiperSlide
                key={element.name}
                data-drets={drets_cat}
                className={styles.slide}
                onMouseEnter={(e) => setClassPos(addSlideClass(e, swiper))}
                virtualIndex={i}
            >
                <CustomContainer tipus={tipus} canal={canal} tipusDirecte={tipusDirecte || element?.ara_fem?.tipus} icona={icona}>
                    <div className={styles.textWrapper}>
                        {tipus === 'DIRECTES' && (
                            <ProgressBar className={styles.progress} start_time={start_time} end_time={end_time} />
                        )}
                        {tipusDirecte === 'radio' ? (
                            <span className='sr-only'>Emissora: {getNomCanal(canal)}</span>
                        ) : (
                            <span className='sr-only'>Canal: {getNomCanal(canal)}</span>
                        )}
                        {itemTitol && <h3 className={styles.titol}>{itemTitol}</h3>}
                        {itemCapitol && <p className={styles.capitol}>{itemCapitol}</p>}
                        {codi_etic && (
                            <CodiEspectador className={styles.codiespectador} codi={codi_etic} alt={codi_etic} mida={20} />
                        )}
                        {tipus === 'DIRECTES' && despresInfo.df_titol && (
                            <p className={styles.despres_fem}>
                                <Icona icona='continuacio' alt='icona a continuació' mida={15} className={styles.ico_next} />A
                                continuació: {despresInfo.df_hora}
                                <span className={styles.despres_info}>
                                    <strong> {despresInfo.df_titol}</strong>
                                    {despresInfo.df_entradeta && <>. {despresInfo.df_entradeta}</>}
                                </span>
                            </p>
                        )}
                    </div>
                    <div className={styles.imatgeWrapper}>
                        <LogoDirecte
                            className={`${canal} ${styles.logocanal}`}
                            canal={_getCanal(canal)}
                            logo={logo}
                            aria-hidden='true'
                        />
                        {icona && (
                            <span className={styleIconaAuriculars}>
                                <Icona icona={icona} className={styles.innerOverIcon} alt='auriculars' />
                            </span>
                        )}
                        <Imatge
                            className={styles.imatge}
                            imatges={imatge}
                            tipus='web_keyframe_directe'
                            mostrarRecursTitol={false}
                            alt={finalInfo.titol}
                            layout='fill'
                            objectFit='cover'
                        />

                        {drets_cat !== undefined && !drets_cat && <span className={styles.etiqueta}>NO DISPONIBLE</span>}
                        {tipus === 'DIRECTES_FUTUR' && (
                            <span className={styles.etiqueta}>{_getEtiquetaDirecteFutur(data_emissio)}</span>
                        )}
                        {drets_cat && (
                            <div className={styles.icona_play}>
                                {isWebviewActive && tipusDirecte === 'radio' ? (
                                    <Icona
                                        icona='auriculars_ple'
                                        className={`${styles.audio} ${styles.play}`}
                                        alt='reproduir'
                                        isBlanc={true}
                                        mida={28}
                                    />
                                ) : (
                                    <Icona icona='play' className={styles.play} alt='reproduir' isBlanc={true} mida={28} />
                                )}
                            </div>
                        )}
                    </div>
                </CustomContainer>
            </SwiperSlide>
        );
    };

    const _iterateItems = () => {
        if (!__isEmpty(itemsOrdenats)) {
            return Array.from(itemsOrdenats).map((element, i) => _buildSlide(element, i));
        }
        return null;
    };

    const routeBotoMes = has_link ? calculaUrlBotoMes(tipus, false, false) : false;

    const _renderSwiper = () => {
        const propsSwiper = getPropsSwiper();
        return (
            <Swiper
                {...propsSwiper}
                ref={swiper}
                navigation={{
                    prevEl: navigationPrev.current,
                    nextEl: navigationNext.current
                }}
                onBeforeInit={(swiper) => {
                    swiper.params.navigation.prevEl = navigationPrev.current;
                    swiper.params.navigation.nextEl = navigationNext.current;
                    //setVisibleSlides(getVisibleSlides(propsSwiper));
                }}
                onAfterInit={() => {
                    setSwiperInit(true);
                }}
                modules={[Navigation, FreeMode]}
                className={stylesSwiper}
                updateOnWindowResize={false}
                onSwiper={(swiper) => {
                    window.addEventListener('resize', function () {
                        clearTimeout(resizeTimeOut);
                        resizeTimeOut = setTimeout(() => {
                            //setVisibleSlides(getVisibleSlides(propsSwiper));
                            swiper.update();
                        }, 100);
                    });
                }}
            >
                <button ref={navigationPrev} className={styles.anterior}>
                    <Icona icona='fletxa_simple_esquerra' alt='Anterior' isBlanc={false} mida={18} />
                </button>
                <button ref={navigationNext} className={styles.seguent}>
                    <Icona icona='fletxa_simple_dreta' alt='Següent' isBlanc={false} mida={18} className={styles.iconadreta} />
                </button>
                {_iterateItems()}
                {routeBotoMes && (
                    <SwiperSlide className={`${styles.ultimslide}`}>
                        <span className={styles.ultim}>
                            <Enllac route={routeBotoMes}>
                                + <span className='sr-only'>{titol}</span>
                            </Enllac>
                        </span>
                    </SwiperSlide>
                )}
            </Swiper>
        );
    };

    return (
        <div className={stylesModul}>
            {getInfoTitol({ titol, iconaTitol, iconaTitolAlt, route: routeBotoMes })}
            {_renderSwiper()}
        </div>
    );
}

Slider.defaultProps = {
    titol: '',
    captitol: '',
    has_link: false,
    canalExclos: '',
    totsCanals: false,
    items: {},
    order: 'tv'
};
